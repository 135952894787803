import React from 'react';
import { Check } from 'lucide-react';

interface Image {
  id: string;
  url: string;
  prompt: string;
  createdAt: string;
  type: string;
}

interface ImageSelectorProps {
  images: Image[];
  onSelect: (url: string) => void;
}

function ImageSelector({ images, onSelect }: ImageSelectorProps) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
      {images.map((image) => (
        <div
          key={image.id}
          onClick={() => onSelect(image.url)}
          className="relative aspect-square rounded-lg overflow-hidden cursor-pointer group"
        >
          <img
            src={image.url}
            alt={image.prompt}
            className="w-full h-full object-cover transition-transform group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
            <button className="bg-white text-gray-900 px-4 py-2 rounded-lg font-medium flex items-center">
              <Check className="w-4 h-4 mr-2" />
              Seç
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ImageSelector;