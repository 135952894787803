import React, { useState, useEffect } from 'react';
import { ShoppingBag, Loader } from 'lucide-react';
import { toast } from 'react-hot-toast';
import OrderForm from './print/OrderForm';
import OrderPreview from './print/OrderPreview';
import ImageSelector from './print/ImageSelector';
import { PrintOrder } from '../types/print';
import { config } from '../config/env';

interface GalleryImage {
  id: string;
  url: string;
  prompt: string;
  createdAt: string;
  type: string;
}

function PrintService() {
  const [order, setOrder] = useState<PrintOrder>({
    type: 'frame',
    size: '50x50',
    frame: 'black',
    address: '',
    phone: '',
    imageUrl: ''
  });
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState<'select' | 'details'>('select');

  useEffect(() => {
    fetchGallery();
  }, []);

  const fetchGallery = async () => {
    try {
      const response = await fetch(`${config.API_URL}/api/gallery`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch gallery');

      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error('Gallery fetch error:', error);
      toast.error('Failed to load gallery');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectImage = (imageUrl: string) => {
    setOrder(prev => ({ ...prev, imageUrl }));
    setStep('details');
  };

  const handleSubmitOrder = async () => {
    if (!order.address || !order.phone) {
      toast.error('Lütfen adres ve telefon bilgilerinizi girin');
      return;
    }

    // Here you would typically send the order to your backend
    toast.success('Siparişiniz alındı! Size en kısa sürede ulaşacağız.');
    setOrder({
      type: 'frame',
      size: '50x50',
      frame: 'black',
      address: '',
      phone: '',
      imageUrl: ''
    });
    setStep('select');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader className="w-8 h-8 animate-spin text-indigo-600" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
            AI ile Özel Baskı Hizmeti
          </h2>
          <p className="text-gray-600 dark:text-gray-400">
            Galerinizdeki görselleri tablo veya t-shirt olarak basın
          </p>
        </div>

        {step === 'select' ? (
          <>
            <OrderForm order={order} onOrderChange={setOrder} />
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">Görsel Seçin</h3>
              <ImageSelector images={images} onSelect={handleSelectImage} />
            </div>
          </>
        ) : (
          <OrderPreview
            order={order}
            onOrderChange={setOrder}
            onBack={() => setStep('select')}
            onSubmit={handleSubmitOrder}
          />
        )}
      </div>
    </div>
  );
}

export default PrintService;