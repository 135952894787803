import React from 'react';
import { PrintOrder } from '../../types/print';

interface OrderPreviewProps {
  order: PrintOrder;
  onOrderChange: (order: PrintOrder) => void;
  onBack: () => void;
  onSubmit: () => void;
}

function OrderPreview({ order, onOrderChange, onBack, onSubmit }: OrderPreviewProps) {
  const prices = {
    frame: {
      '50x50': 1000,
      '96x90': 1500
    },
    tshirt: 300
  };

  return (
    <div className="space-y-6">
      {order.imageUrl && (
        <div className="mb-6">
          <img
            src={order.imageUrl}
            alt="Generated design"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Adres
        </label>
        <textarea
          value={order.address}
          onChange={(e) => onOrderChange({ ...order, address: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
          rows={3}
          placeholder="Teslimat adresi..."
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Telefon
        </label>
        <input
          type="tel"
          value={order.phone}
          onChange={(e) => onOrderChange({ ...order, phone: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:text-white"
          placeholder="05XX XXX XX XX"
        />
      </div>

      <div className="flex justify-between items-center p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
        <div className="text-gray-700 dark:text-gray-300">Toplam Tutar:</div>
        <div className="text-xl font-bold text-gray-900 dark:text-white">
          {order.type === 'frame' 
            ? `${prices.frame[order.size!]} TL`
            : `${prices.tshirt} TL`
          }
        </div>
      </div>

      <div className="flex gap-4">
        <button
          onClick={onBack}
          className="flex-1 px-6 py-3 border border-gray-300 dark:border-gray-600 rounded-lg font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
        >
          Geri
        </button>
        <button
          onClick={onSubmit}
          className="flex-1 bg-indigo-600 text-white py-3 px-6 rounded-lg font-medium hover:bg-indigo-700 transition-colors"
        >
          Siparişi Tamamla
        </button>
      </div>
    </div>
  );
}

export default OrderPreview;