import React from 'react';
import { Frame, Shirt } from 'lucide-react';
import { PrintOrder } from '../../types/print';

interface OrderFormProps {
  order: PrintOrder;
  onOrderChange: (order: PrintOrder) => void;
}

function OrderForm({ order, onOrderChange }: OrderFormProps) {
  const prices = {
    frame: {
      '50x50': 1000,
      '96x90': 1500
    },
    tshirt: 300
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Ürün Tipi
        </label>
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => onOrderChange({ ...order, type: 'frame' })}
            className={`p-4 rounded-lg border ${
              order.type === 'frame'
                ? 'border-indigo-600 bg-indigo-50 dark:bg-indigo-900/20'
                : 'border-gray-300 dark:border-gray-600'
            } flex items-center justify-center gap-2`}
          >
            <Frame className="w-5 h-5" />
            Tablo
          </button>
          <button
            onClick={() => onOrderChange({ ...order, type: 'tshirt' })}
            className={`p-4 rounded-lg border ${
              order.type === 'tshirt'
                ? 'border-indigo-600 bg-indigo-50 dark:bg-indigo-900/20'
                : 'border-gray-300 dark:border-gray-600'
            } flex items-center justify-center gap-2`}
          >
            <Shirt className="w-5 h-5" />
            T-Shirt
          </button>
        </div>
      </div>

      {order.type === 'frame' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Boyut ve Çerçeve
          </label>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => onOrderChange({ ...order, size: '50x50', frame: 'black' })}
              className={`p-4 rounded-lg border ${
                order.size === '50x50'
                  ? 'border-indigo-600 bg-indigo-50 dark:bg-indigo-900/20'
                  : 'border-gray-300 dark:border-gray-600'
              }`}
            >
              <div className="font-medium">50x50 cm</div>
              <div className="text-sm text-gray-600 dark:text-gray-400">Siyah Çerçeveli</div>
              <div className="mt-2 font-bold">1000 TL</div>
            </button>
            <button
              onClick={() => onOrderChange({ ...order, size: '96x90', frame: 'none' })}
              className={`p-4 rounded-lg border ${
                order.size === '96x90'
                  ? 'border-indigo-600 bg-indigo-50 dark:bg-indigo-900/20'
                  : 'border-gray-300 dark:border-gray-600'
              }`}
            >
              <div className="font-medium">96x90 cm</div>
              <div className="text-sm text-gray-600 dark:text-gray-400">3 Parçalı Çerçevesiz</div>
              <div className="mt-2 font-bold">1500 TL</div>
            </button>
          </div>
        </div>
      )}

      {order.type === 'tshirt' && (
        <div className="p-4 rounded-lg border border-gray-300 dark:border-gray-600">
          <div className="font-medium">T-Shirt Baskı</div>
          <div className="text-sm text-gray-600 dark:text-gray-400">Yüksek kalite baskı</div>
          <div className="mt-2 font-bold">300 TL</div>
        </div>
      )}
    </div>
  );
}

export default OrderForm;